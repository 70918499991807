'use client';

import { create } from 'zustand';

interface IFilterState {
  showModal: boolean;
  modalType?: string | null;
  closeModal: () => void;
  openModal: (type?: string) => void;
}

export const useModalStore = create<IFilterState>((set) => ({
  showModal: false,
  modalType: null,
  closeModal: () => set(() => ({ showModal: false, modalType: null })),
  openModal: (type?: string) =>
    set(() => ({ showModal: true, modalType: type })),
}));
