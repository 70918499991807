'use client'
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Link from 'next/link';
import { setUrl } from '@utils/url';
import { useModalStore } from '@contexts/ui/basic-modal-context';
import { HeroModal } from './hero-carousel-modal';
import { TPromoBanner } from '@services/store-admin/types';
import { HeroSlideImages } from './hero-slide-images';

export default function HeroCarousel({
  promotions,
  lang,
}: {
  promotions: TPromoBanner[];
  lang: string;
}) {  

    const { showModal, closeModal, openModal, modalType } = useModalStore();
    const [modalContent, setModalContent] = useState({
        title: '',
        description: '',
    });

    const handleOpenModal = (title: string, description: string) => {
        setModalContent({ title, description });
        openModal(title);
    };    

  return (
    <>
        <div id="hero-carousel" className='relative'>
        {promotions && <Swiper                        
          loop
          navigation
          modules={[Autoplay, Pagination, Navigation]}
          className="welcome-banner"
          >
          {promotions.map((promo, index) => (
            <SwiperSlide className="bg-center bg-cover xs:min-h-[518px] sm:min-h-[518px] md:min-h-[602px] xl:min-h-[602px]" key={promo._id || index}>                
              <Link href={setUrl(promo.promo_url, lang)}>
                
                <HeroSlideImages promo={promo} />

                {promo.tnc_title && (
                  <div
                    className="absolute text-white text-sm left-1 lg:left-2 top-1 lg:top-auto lg:!bottom-2 underline underline-offset-1 cursor-pointer hover:text-brand z-50"
                    onClick={(e) => {
                      e.preventDefault();
                      handleOpenModal(promo.tnc_title, promo.tnc_description);
                    }}
                  >
                    Terms and Conditions
                  </div>
                )}
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>}      
        </div>
      {promotions && showModal && modalType === modalContent.title && (
        <HeroModal
          showAlert={showModal}
          closeAlert={closeModal}
          title={modalContent.title}
          description={modalContent.description}
        />
      )}
    </>
  );
}
