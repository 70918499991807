import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.69.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.69.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.69.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.69.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.69.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.69.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.69.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.69.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.69.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.69.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.69.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.69.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/home/jewel-store/node_modules/.pnpm/@tanstack+react-query@5.69.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/node_modules/.pnpm/next@14.2.25_@babel+core@7.26.10_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/node_modules/.pnpm/next@14.2.25_@babel+core@7.26.10_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/node_modules/.pnpm/next@14.2.25_@babel+core@7.26.10_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["TrendCarousel"] */ "/home/jewel-store/src/components/product/feeds/jewel-browse-feed.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GalleryCarousel"] */ "/home/jewel-store/src/components/product/feeds/jewel-gallery-feed.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SettingCarousel"] */ "/home/jewel-store/src/components/product/feeds/jewel-setting-feed.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/jewel-store/src/components/ui/hero-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactOptions"] */ "/home/jewel-store/src/content/pages/front-main/contact-options.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EvergreenBanner"] */ "/home/jewel-store/src/content/pages/front-main/EvergreenBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Questionaire"] */ "/home/jewel-store/src/content/pages/front-main/questionaire.tsx");
