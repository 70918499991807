'use client';

import Carousel from '@components/ui/carousel/carousel';
import { SwiperSlide } from '@components/ui/carousel/slider';
import Heading from '@components/ui/heading';
import { setUrl } from '@utils/url';
import Image from 'next/image';
import Link from 'next/link';
import clsx from 'clsx';

const breakpoints = {
  '1024': {
    slidesPerView: 5.5,
    spaceBetween: 12,
  },
  '768': {
    slidesPerView: 4,
    spaceBetween: 12,
  },
  '680': {
    slidesPerView: 4,
    spaceBetween: 12,
  },
  '375': {
    slidesPerView: 1.5,
    spaceBetween: 4,
  },
  '0': {
    slidesPerView: 1.5,
  },
};

interface TSettingType {
  id?: string;
  title: string;
  image: string;
  url?: string;
  alt?: string;
}

interface SettingCarouselProps {
  lang: string;
  className?: string;
  heading: string;
  paragraph?: string;
  data: TSettingType[];
  carouselBreakpoint?: {} | any;
}

export const SettingCarousel = ({
  lang,
  className,
  heading,
  paragraph,
  data,
  carouselBreakpoint,
}: SettingCarouselProps) => {
  return (
    <div
      className={`max-w-[1440px] px-4 py-6 md:px-6 lg:px-0 relative mx-auto bg-white my-8 sm:my-8 text-center ${className}`}
    >
      <div className="flex flex-wrap items-center justify-center mb-3 md:mb-6">
        <div className="-mt-1.5">
          <Heading variant="jewelCarouselHeader" className="mb-0">
            {heading}
          </Heading>
        </div>
      </div>
      {paragraph && (
        <div className="flex items-center justify-center mt-2 lg:-mt-2 mb-3 md:mb-6">
          <p className="font-outfit text-15px lg:text-[17px] leading-5 lg:leading-7">
            {paragraph}
          </p>
        </div>
      )}
      <div className="mx-auto w-full">

        <div className='xs:block sm:block md:hidden lg:hidden xl:hidden'>
              <div className="mt-3 grid grid-cols-2 gap-x-2 gap-y-4 lg:gap-y-8 lg:grid-cols-2 xl:gap-x-6">
                {data.map((collection: TSettingType, idx) => (
                  <div
                    key={`bc-${idx}`}
                    className="group relative cursor-pointer"
                  >
                    <Link href={setUrl(collection.url, lang)}>
                      <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-100">
                        <img
                          src={collection.image}
                          alt={collection.alt}
                          className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                        />
                      </div>
                      <div className="mt-1 lg:mt-2">
                        <div>
                          <h3 className="font-cardo text-sm lg:text-lg font-normal text-brand-grey-0 text-center">
                            <span aria-hidden="true" className="absolute inset-0" />
                            {collection.title}
                          </h3>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
        </div>

        <div className='xs:hidden sm:hidden md:block lg:block xl:block'>
        <Carousel
          breakpoints={carouselBreakpoint || breakpoints}
          lang={lang}
          prevActivateId="setting-carousel-button-prev"
          nextActivateId="setting-carousel-button-next"
        >
          {data.map((value: TSettingType, idx) => (
            <SwiperSlide key={`jewelsetting-key-${idx}`}>
              <Link
                href={setUrl(value.url, lang)}
                className={clsx(
                  'cursor-pointer border-2 border-transparent flex flex-col justify-center',
                )}
              >
                <div className="relative">
                  <div className="overflow-hidden mx-auto w-full relative">
                    <Image
                      src={value.image}
                      alt={value.alt || value.title}
                      quality={100}
                      priority
                      width={360}
                      height={360}
                      className="object-cover bg-fill-thumbnail"
                    />
                  </div>
                </div>

                <h3 className="px-2 py-2.5 text-brand-grey-0 font-cardo text-lg font-normal capitalize">
                  {value.title}
                </h3>
              </Link>
            </SwiperSlide>
          ))}
        </Carousel>
        </div>

      </div>
    </div>
  );
};
