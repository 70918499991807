import React from 'react';

import Modal from '@components/common/modal/modal';

import { IoClose } from 'react-icons/io5';

type TProps = {
  showAlert: boolean;
  closeAlert: () => void;
  title: string;
  description: string;
};

export const HeroModal = ({
  showAlert,
  closeAlert,
  title,
  description,
}: TProps) => {
  return (
    <Modal open={showAlert} onClose={closeAlert}>
      <div className="relative w-full max-h-[90vh] bg-white px-4 py-6 rounded-sm md:max-w-[600px] overflow-y-auto">
        <button
          type="button"
          onClick={closeAlert}
          aria-label="Close Button"
          className="absolute z-10 inline-flex items-center justify-center w-8 h-8 lg:w-9 lg:h-9 transition duration-200 text-brand-dark text-opacity-50 focus:outline-none  hover:text-opacity-100 bg-brand-light lg:bg-transparent top-0 right-0"
        >
          <IoClose className="text-xl lg:text-2xl" />
        </button>

        <div className="p-2">
          <h2 className="capitalize text-center font-semibold font-cardo text-lg">
            term and conditions
          </h2>
          <p className="text-left font-semibold mt-3 mb-2 text-base">{title}</p>
          <p
            className="text-left text-sm"
            dangerouslySetInnerHTML={{ __html: description }}
          ></p>
        </div>
      </div>
    </Modal>
  );
};
