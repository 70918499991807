import { TPromoBanner } from '@services/store-admin/types';
import React from 'react'

export const HeroSlideImages = ({promo}:{promo: TPromoBanner}) => {
  return (
    <> 
        <img sizes="(min-width: 1200px),
            (max-width: 1199px),
            (max-width: 380px)"
            srcSet={`${promo.promo_banner} 1x, ${promo.promo_banner_xs} 2x`}
            src={promo.promo_banner}
            alt={promo.promo_alt} />
    </>
  );
}